<template>
  <Container :horizontal-padding="hPadding">
    <div
      class="product-feature"
      :style="{ backgroundImage: `url(${image || product.productImageUrl})` }"
    >
      <div class="product-feature-content">
        <span class="text-md">{{ teaser }}</span>
        <span class="product-feature-title">
          {{ title }}
        </span>
        <h2 class="mb-2 text-h3 font-semibold mt-0 font-headline">
          {{ product.productName }}
        </h2>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="product-feature-text" v-html="description" />
        <Button
          size="medium"
          :rounded-corners="false"
          :to="product.productUrl"
          class="product-feature-button"
        >
          {{ buttonText ? buttonText : `Shop ${product.productName}` }}
        </Button>
      </div>
    </div>
  </Container>
</template>

<script>
  import Container from "~/components/Container"
  import Button from "~/components/Button"

  export default {
    name: "ProductFeature",
    components: {
      Container,
      Button,
    },
    props: {
      teaser: {
        type: String,
        default: null,
      },
      product: {
        type: Object,
        default: () => {
          return {
            productImageUrl: "",
          }
        },
      },
      hPadding: {
        type: Boolean,
        default: true,
      },
      title: {
        type: String,
        default: null,
      },
      description: {
        type: String,
        default: null,
      },
      buttonText: {
        type: String,
        default: null,
      },
      image: {
        type: String,
        default: "",
      },
    },
  }
</script>

<style scoped lang="scss">
  .product-feature {
    color: white;
    position: relative;
    background-size: cover;
    border-radius: $border-radius;
    overflow: hidden;
  }
  .product-feature-title {
    @include type("sm");
  }
  .product-feature-content {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: $space-m;

    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    @include viewport("lg") {
      max-width: $base-spacing * 111;
    }
    @include viewport("mini", "sm", "m") {
      padding: $base-spacing * 6;
    }
  }

  .product-feature-text {
    margin-bottom: $space-s;
    max-width: $base-spacing * 111;
  }

  .product-feature-button .button-text.button-text {
    color: $onyx;
  }
</style>
